import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/components/mdx-layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`I love Japan, I've been to Japan twice and am always thinking about going back. This wiki page will broadly document what we did on both trips, and highlight some recommendations, particularly where we found seasonal events or strayed from the beaten path.`}</p>
    <p>{`Tokyo/Osaka/Kyoto are great, and you should definitely spend some time in some or all of them if its your first time in Japan, but I really encourage going further afield. We've found a number of unique spots that aren't on the usual tourism checklists. It can be a little scary straying from comfortable cities, but anywhere remotely touristy will accommodate you in some way at least, whether you speak Japanese or not.`}</p>
    <h3 {...{
      "id": "about-our-trips"
    }}>{`About our trips`}</h3>
    <ol>
      <li parentName="ol">{`Winter 2016 - Two weeks`}</li>
    </ol>
    <ul>
      <li parentName="ul">{`We hit a few tourist spots but tried to not treat it like a checklist and avoided staying in one place too long`}</li>
      <li parentName="ul">{`On this trip we stayed in `}</li>
      <li parentName="ul">{`We stayed in Japan for Christmas and flew back on Boxing Day`}</li>
    </ul>
    <ol {...{
      "start": 2
    }}>
      <li parentName="ol">{`Spring 2018 - Three weeks`}</li>
    </ol>
    <ul>
      <li parentName="ul">{`This time we went in spring to see the cherry blossoms and planned a trip where we could follow them from the south (Kyushu) up as far as Sendai`}</li>
      <li parentName="ul">{`This time we made more day trips out, particularly in Kyushu where we treated Fukuoka as a base`}</li>
      <li parentName="ul">{`25th birthday`}</li>
    </ul>
    {
      /*[Skip to recommendations](#recommendations)*/
    }
    {
      /*
      ## Trip 1 - Winter 2016 - Two weeks
      ### Intro
      Our first trip, ...
      Christmas
      ### Flight
      We flew with LOT, a Polish airline, via Warsaw Chopin Airport, not a particularly inspiring airport to transfer through, but the flights themselves were good. The shorter flights from Heathrow to 
      ### Tokyo
      #### Hotel
      While in Tokyo we stayed at Apartment Hotel in Shinjuku
      #### Entertainment
      #### Food
      #### Excursions
      
      `//TODO`
      ### Return to Tokyo
      ### Airport
      ### Bonus - Warsaw
      Ventured out into Warsaw, was somewhat stressful as roads were closed for the season and we struggled to find a bus back, eventually opting for a taxi (which was thankfully cheap, although the driver wasn't happy about me paying by card)
      ## Trip 2 - Spring 2018
      ### Intro
      Our second trip, sakura, 25th birthday
      ### Flight
      
      ### Tokyo
      #### Hotel
      We returned to Apartment Hotel again, as the location's good and it's a nice little place
      `//TODO`
      */
    }
    <h1 id="recommendations">Recommendations</h1>
    <h2 {...{
      "id": "tokyo"
    }}>{`Tokyo`}</h2>
    <h3 {...{
      "id": "day-trips"
    }}>{`Day trips`}</h3>
    <ul>
      <li parentName="ul">{`Ghibli Museum - `}</li>
      <li parentName="ul">{`Odaiba - Walk across the bridge (closes @ X:00, we had to get a bus back), giant Gundam, we went whent he original one was there, but I'd love to go back and see the newer one`}</li>
    </ul>
    <h2 {...{
      "id": "nara"
    }}>{`Nara`}</h2>
    <p>{`Nara's a good day trip spot from Kyoto or Osaka`}</p>
    <h3 {...{
      "id": "attractions"
    }}>{`Attractions`}</h3>
    <ul>
      <li parentName="ul">{`Giant Buddha`}</li>
      <li parentName="ul">{`Deer - so distracted we forgot why we were there`}</li>
    </ul>
    <h2 {...{
      "id": "kyoto"
    }}>{`Kyoto`}</h2>
    <h2 {...{
      "id": "osaka"
    }}>{`Osaka`}</h2>
    <p>{`I'm kind of indifferent to Osaka`}</p>
    <h2 {...{
      "id": "nagoya"
    }}>{`Nagoya`}</h2>
    <p>{`We only stayed in Nagoya for a couple of nights, it was a convenient spot to travel up to Takayama matsuri (the train ride was long, but the views were great) and I had a few food recommendations from my Japanese teacher`}</p>
    <h3 {...{
      "id": "food"
    }}>{`Food`}</h3>
    <ul>
      <li parentName="ul">{`Yamachan`}</li>
      <li parentName="ul">{`pork place...`}</li>
    </ul>
    <h3 {...{
      "id": "accommodation"
    }}>{`Accommodation`}</h3>
    <ul>
      <li parentName="ul">{`Book...`}</li>
    </ul>
    <h2 {...{
      "id": "sendai"
    }}>{`Sendai`}</h2>
    <h3 {...{
      "id": "day-trips-1"
    }}>{`Day trips`}</h3>
    <ul>
      <li parentName="ul">{`slope car`}</li>
      <li parentName="ul">{`lotsa islands place`}</li>
    </ul>
    <h2 {...{
      "id": "hiroshima"
    }}>{`Hiroshima`}</h2>
    <h3 {...{
      "id": "food-1"
    }}>{`Food`}</h3>
    <h3 {...{
      "id": "attractions-1"
    }}>{`Attractions`}</h3>
    <ul>
      <li parentName="ul">{`Miyajima - famous torii gate in the sea, deer, supposedly less polite than Nara's, but I found them to be much more chilled out`}</li>
      <li parentName="ul">{`Peace Museum`}</li>
    </ul>
    <h3 {...{
      "id": "accommodation-1"
    }}>{`Accommodation`}</h3>
    <h3 {...{
      "id": "day-trips-2"
    }}>{`Day trips`}</h3>
    <p>{`Okunoshima (Bunny Island)`}</p>
    <h2 {...{
      "id": "kinosaki"
    }}>{`Kinosaki`}</h2>
    <h2 {...{
      "id": "fukuoka"
    }}>{`Fukuoka`}</h2>
    <h3 {...{
      "id": "food-2"
    }}>{`Food`}</h3>
    <ul>
      <li parentName="ul">{`Raumen Stadium - Hakata style, tomato ramen`}</li>
    </ul>
    <h3 {...{
      "id": "day-trips-3"
    }}>{`Day trips`}</h3>
    <ul>
      <li parentName="ul">{`Kumamoto - castle, destroyed by an earthquake`}</li>
      <li parentName="ul">{`Beppu - 7 Hells/Jigoku, we made the mistake of not trying the puddings at the first one thinking there'd be more opportunities, convenient buses to get around`}</li>
    </ul>
    <h2 {...{
      "id": "kanagawa"
    }}>{`Kanagawa`}</h2>
    <h2 {...{
      "id": "kanazawa"
    }}>{`Kanazawa`}</h2>
    <p>{`We visited Kanazawa for the castle garden while the blossoms were out, and only stayed a night, but filled the day with a number of museums`}</p>
    <h3 {...{
      "id": "attractions-2"
    }}>{`Attractions`}</h3>
    <ul>
      <li parentName="ul">{`Phonograph Museum - Regular talks going through a number of vintage phonographs, accommodated us with print-outs of the talk in English. Happened to be there on a Sunday too, when they have a demonstration of a self-playing piano which was a treat (although this lacked any English)`}</li>
      <li parentName="ul">{`Gold Leaf Museum - `}</li>
      <li parentName="ul">{`Sweets Museum - `}</li>
    </ul>
    <h3 {...{
      "id": "fooddrink"
    }}>{`Food/Drink`}</h3>
    <ul>
      <li parentName="ul">{`Oriental Brewing - A micro-brewery and pizza `}</li>
    </ul>
    <h3 {...{
      "id": "accommodation-2"
    }}>{`Accommodation`}</h3>
    <h2 {...{
      "id": "omatsuri-festivals"
    }}>{`Omatsuri (Festivals)`}</h2>
    <p>{`Another consideration we make when we visit Japan is seeing if there are any festivals or anything going on while we're out there and planning where we are around them. Exciting to be immersed in Japanese`}</p>
    <ul>
      <li parentName="ul">{`Kyoto (Arashiyama) - Lantern Fesitval`}</li>
      <li parentName="ul">{`Kyoto (Sakura) - obakeyashiki (haunted house)`}</li>
      <li parentName="ul">{`Nara`}</li>
      <li parentName="ul">{`Beppu - Didn't have time to get involved, saw some mascots but we were there to see the Hells`}</li>
      <li parentName="ul">{`Takayama - the town is known for its sake and a number of shops have a sake tasting where you buy a cup and keep filling it up from the fridge, and take your cup home when you're done, the festival itself was amazing, massive intricate floats, and puppet shows`}</li>
      <li parentName="ul">{`Hanami Tokyo`}</li>
    </ul>
    <h2 {...{
      "id": "chain-food"
    }}>{`Chain Food`}</h2>
    <ul>
      <li parentName="ul">{`GoGo Curry - I see Coco Ichiban gets a lot of love from my fellow weebs, but I can't eat their curry sauce due to allergies, but GoGo Curry do some `}</li>
      <li parentName="ul">{`Ichiran Ramen - Individual booths, served tonkotsu (pork bone) ramen made to your liking. Personally I like my broth rich and my noodles firm`}</li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      